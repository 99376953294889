<template>
  <!-- dialog START -->
  <transition name="dialog-fade" mode="out-in">
    <div class="loading loading-info" v-if="dialogVisible">
      <div class="loading-info-container">

        <!-- dialog:content START -->
        <div class="loading-info-container-inner">
          <h4 v-html="headline"></h4>
          <div class="info-excerpt" v-html="excerpt"></div>
          <div class="info-description" v-html="description"></div>
        </div>
        <!-- dialog:content END -->

        <!-- dialog:close START -->
        <div
          class="loading-info-container-close"
          v-on:click="dialogClose()"
        >
          <i class="el-dialog__close el-icon el-icon-close"></i>
        </div>
        <!-- dialog:close END -->

      </div>
    </div>
  </transition>
  <!-- dialog END -->
</template>

<script>
export default {
  name: 'dialog-info',
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },

    headline: {
      type: String,
    },

    excerpt: {
      type: String,
    },

    description: {
      type: String,
    },

    dialogClose: {
      type: Function,
    },
  },
};
</script>
